import { Api } from "@/apis";
export const allProps = {
  // labelWidth: "200px",
  /* refName:{
    search:"submitForm",
    rule:"ruleForm"
  }, */
  isShowBtn: {
    // searchBtn: true,
    addBtn: true,
  },

  // searchData:{},
  // rules:false,
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: { dizhi: [] /* regino: 2,resource: 2 */ },
  items: [
    { label: "门店id", prop: "id", type: ["table"] },
    {
      label: "门店主",
      prop: "owner_id",
      Ftype: "select",
      list: [],
      type: ["add"],
      filterable: true,
    },
    { label: "店名", prop: "name", type: ["table"] },
    { label: "店名", prop: "ename", type: [ "add", "edit"] },
    {
      label: "区号",
      prop: "area_code",
      type: ["table", "add", "edit"],
      // rule: false,
    },
    { label: "地址", prop: "province", Ttype: "slot", type: ["table"] },
    {
      label: "状态",
      prop: "state",
      Ttype: "slot",
      Ftype: "radio",
      list: [
        { label: "运营", value: 1 },
        { label: "暂停", value: 0 },
      ],
      rule: [{ required: true, message: "请选择", trigger: "change" }],
      type: ["table", "add", "edit"],
    },
    { label: "收款银行", prop: "bank", type: ["table", "add", "edit"] },
    { label: "银行卡号", prop: "card_number", type: ["table", "add", "edit"] },
    {
      label: "地址",
      prop: "dizhi",
      Ftype: "cascader",
      options: [],
      type: ["add"],
      filterable: true,
    },
    {
      label: "详细地址",
      prop: "address",
      Ftype: "textarea",
      type: ["add"],
    },
    { label: "经度", prop: "longitude", type: ["add", "edit"] },
    { label: "纬度", prop: "latitude", type: ["add", "edit"] },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "150px",
    },
  ],
  api: Api.shop,
};
export const column = {
  // type: ["index", "selection"],
  props: [
    { label: "日期", prop: "date" },
    { label: "日期", prop: "address" },
    { label: "图片", prop: "img", type: "image" },
    {
      label: "性别",
      prop: "sex",
      type: "function",
      callback: (data) => {
        return data.sex == 1
          ? `<i class="el-icon-time"></i>
    <span style="color:blue"> 男</span>`
          : `<i class="el-icon-time"></i>
    <span style="color:red"> 女</span>`;
      },
    },
    {
      label: "操作",
      prop: "operation",
      type: "slot",
      slot_name: "operation",
    },
  ],
};
export const addProp = {
  labelWidth: "100px",
  refName: "ruleForm",
  isShowBtn: true,
  data: { type: [] /* regino: 2,resource: 2 */ },

  items: [
    {
      type: "select",
      list: [
        { label: "区域一", value: 1 },
        { label: "区域二", value: 2 },
      ],
      placeholder: "请选择活动区域",
      label: "活动区域",
      prop: "regino",
      rules: [{ required: true, message: "请选择", trigger: "change" }],
    },
    {
      type: "datePicker",
      label: "活动时间",
      prop: "date",
      // dateProps:['star','end','YYYYMMDD'],
      rules: [{ required: true, message: "请选择", trigger: "change" }],
    },
    {
      label: "活动名称",
      prop: "name",
      rules: [{ required: true, message: "请填写", trigger: "change" }],
    },
    {
      type: "switch",
      label: "及时配送",
      prop: "delivery",
    },
    {
      type: "checkbox",
      label: "活动性质",
      prop: "type",
      list: [
        { label: "美食/餐厅线上活动", value: 1 },
        { label: "地推活动", value: 2 },
        { label: "线下主题活动", value: 3 },
        { label: "单纯品牌曝光", value: 4 },
      ],
      rules: [
        {
          type: "array",
          required: true,
          message: "请至少选择一个活动性质",
          trigger: "change",
        },
      ],
    },
    {
      type: "radio",
      label: "特殊资源",
      prop: "resource",
      list: [
        { label: "线上品牌商赞助", value: 1 },
        { label: "线下场地免费", value: 2 },
      ],
      rules: [{ required: true, message: "请选择", trigger: "change" }],
    },
    {
      type: "textarea",
      label: "描述",
      prop: "desc",
      rows: 4,
      rules: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
    },
  ],
};
