<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:province="scope">
        <span>{{
          scope.data.province + scope.data.city + scope.data.address
        }}</span>
      </template>
      <template v-slot:state="scope">
        <span>{{ scope.data.state ? "运营" : "暂停" }}</span>
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('编辑', scope.data)"
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import  {messageBox}  from "@/utils/index";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: this.changeRuleForm,
    };
  },
  data() {
    return {
      tableData: {},
      allProps,
      Api,
    };
  },
  created() {
    let { name, token } = JSON.parse(localStorage.userInfo);
    this.$axios.post(Api.user.get, { name, token }).then((res) => {
      this.allProps.items.forEach((item) => {
        if (item.prop === "dizhi") item.options = this.$store.state.space;
        if (item.prop === "owner_id") {
          item.list = res.data.data;
        }
      });
    });
  },
  methods: {
    setInnerHtml(e) {
      this.$nextTick(() => {
        let btnForm = this.$refs.dialogForm.$refs.btnForm;
      });
    },
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      allProps.data = { ...row };
    },
    changeRuleForm(data) {
      console.log("hyiuggggg");
      if (this.allProps.dialog.title == "新增") {
        [data.province, data.city] = data.dizhi;
        delete data.dizhi;
        return data;
      }
    },
    async deleteRow(id) {
      let result = await messageBox(Api.shop.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
